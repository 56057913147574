import { useState } from "react";

interface BannerSectionCardsProps {
  src?: any,
  id?: number,
  title?: string;
  desc?: string
}

const BannerSectionCards = (props: BannerSectionCardsProps) => {

  const [hover, setHover] = useState(false)
  // console.log(hover, "# hover")

  return (
    <div
      className={`${hover ? 'shadow-[0_6px_15px_rgba(0,0,0,0.3)]' : 'shadow-[0_1px_5px_rgba(0,0,0,0.3)]'} relative cursor-pointer border-2 border-[#121211] rounded-md  shadow-[#FFEB00]/[50%] p-3 lg:p-7`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={props.src} alt="templateIcon2" className='absolute p-3 top-0 right-0 border-[#121211] rounded-full shadow-[0_5px_5px_rgba(0,0,0,0.3)] max-w-[18%] lg:max-w-[100%]' />
      <div className="text-[#C5B60A] text-4 lg:text-2xl font-extrabold flex gap-2 items-center">
        <h1 className="text-[1.75rem] lg:text-[2.75rem] font-[IBM Plex Sans]">{props.id}</h1>
        <h1>{props.title}</h1>
      </div>
      <p className="text-[0.8rem] text-[#CBC8C8] lg:text-base px-1 lg:px-5 mt-4 lg:mt-8 pb-6 lg:pb-10">{props.desc}</p>
    </div>
  )
}

export default BannerSectionCards